import React, { FC } from "react";
import { GridProvider } from "@health/domains";
import { H_OrderDirection, TemplateActivityEventItemSortingField } from "@health/queries/types";
import { useGuidedCareTemplateActivitiesItemListQuery } from "pages/Templates/gql";
import { useGuidedCareTemplateActivitiesItemListColumns } from "./useGuidedCareTemplateActivitiesItemListColumns";
import { TableGrid } from "@toolkit/ui";

type GuidedCareTemplateActivitiesItemListProps = {
  templateId: string;
  minOffsetInDays?: number;
  maxOffsetInDays?: number;
};

export const GuidedCareTemplateActivitiesItemList: FC<GuidedCareTemplateActivitiesItemListProps> = props => {
  const { templateId, minOffsetInDays, maxOffsetInDays } = props;

  return (
    <GridProvider
      gridName={"guidedCareTemplateActivitiesItemList"}
      columns={useGuidedCareTemplateActivitiesItemListColumns()}
      query={useGuidedCareTemplateActivitiesItemListQuery}
      variables={{
        filter: { templateId, offsetGreaterThanOrEquals: minOffsetInDays, offsetLessThanOrEquals: maxOffsetInDays },
        sortBy: {
          field: TemplateActivityEventItemSortingField.Offset,
          direction: H_OrderDirection.Asc,
        },
      }}
      pageSize={8}
      skipCall={!templateId}
    >
      <TableGrid />
    </GridProvider>
  );
};
