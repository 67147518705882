import React from "react";
import { useParams } from "react-router-dom";
import { PageWrapper } from "@toolkit/ui";
import { useSetGuidedCareTemplatesBreadcrumbs } from "pages/Templates/hooks";
import { IGuidedCareTemplateActivitiesItemsTimelineContainerParams } from "pages/Templates/types";
import { GuidedCareTemplateActivitiesItemsTimeline } from "../../components/TemplateActivitiesItemsTimeline/GuidedCareTemplateActivitiesItemsTimeline";
import { useTemplateBreadcrumbGetQuery } from "@/pages/Templates/gql";

export const GuidedCareTemplateActivitiesItemsTimelineContainer = () => {
  const { templateId } = useParams<IGuidedCareTemplateActivitiesItemsTimelineContainerParams>();

  const { data } = useTemplateBreadcrumbGetQuery({
    variables: {
      id: templateId!,
    },
    skip: !templateId,
  });

  useSetGuidedCareTemplatesBreadcrumbs("ACTIVITIES_ITEM_LIST", data?.healthProgramTemplate?.name!);

  return (
    <PageWrapper>
      <GuidedCareTemplateActivitiesItemsTimeline />
    </PageWrapper>
  );
};
