import { useParams } from "react-router-dom";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import {
  useGuidedCareTemplateInterventionActivityGetQuery,
  useGuidedCareTemplateInterventionActivityItemsUpdateMutation,
} from "pages/Templates/gql";
import { useSetGuidedCareTemplatesBreadcrumbs } from "pages/Templates/hooks";
import { convertActivityItemsFormValuesToBackEndValues } from "pages/Templates/others";
import {
  IGuidedCareTemplateInterventionActivityItemListContainerParams,
  IGuidedCareTemplateInterventionActivityItemsFormEvent,
} from "pages/Templates/types";
import { GuidedCareTemplateInterventionActivityItemsForm } from "../../forms/TemplateInterventionActivityItems/GuidedCareTemplateInterventionActivityItemsForm";
import { TemplateInterventionActivity } from "@health/queries/types/types";
import { guidedCareActivityTypeOptionsMap } from "@health/enum-options";

export const GuidedCareTemplateInterventionActivityItemListContainer = () => {
  const { t } = useTranslation("gcadmin");
  const { failed, succeeded } = useAddToast();

  const { activityId } = useParams<IGuidedCareTemplateInterventionActivityItemListContainerParams>();

  const { data, loading, refetch } = useGuidedCareTemplateInterventionActivityGetQuery({
    variables: {
      activityId: activityId!,
    },
  });

  const templateInterventionActivity = data?.templateInterventionActivity as TemplateInterventionActivity;

  const [updateGuidedCareTemplateInterventionActivityItems, { loading: isSubmitting }] =
    useGuidedCareTemplateInterventionActivityItemsUpdateMutation({
      onCompleted: mutationData => {
        if (!mutationData?.updateTemplateInterventionActivityItems?.templateActivityErrors?.length) {
          succeeded(t("Activity items have been updated successfully"));
        } else {
          failed(t("Activity items update failed"));
        }
      },
      onError: ({ graphQLErrors }) => {
        failed(formatGraphQLError(graphQLErrors));
      },
    });

  const onGuidedCareTemplateInterventionActivityItemsFormChange = (event: IGuidedCareTemplateInterventionActivityItemsFormEvent) => {
    if (event.type === "SUBMIT") {
      updateGuidedCareTemplateInterventionActivityItems({
        variables: {
          activityId: activityId!,
          activityItems: convertActivityItemsFormValuesToBackEndValues(event.payload.values.activityItems),
        },
      }).then(() => refetch());
    }
  };

  useSetGuidedCareTemplatesBreadcrumbs(
    "ACTIVITY_ITEM_LIST",
    guidedCareActivityTypeOptionsMap[templateInterventionActivity?.templateActivityType!]?.label
  );

  return (
    <GuidedCareTemplateInterventionActivityItemsForm
      activity={templateInterventionActivity}
      isLoading={loading || isSubmitting}
      onChange={onGuidedCareTemplateInterventionActivityItemsFormChange}
    />
  );
};
