import React from "react";
import { useParams } from "react-router-dom";
import { useSetGuidedCareTemplatesBreadcrumbs } from "pages/Templates/hooks";
import { IGuidedCareTemplateActivitiesItemsManageContainerParams } from "pages/Templates/types";
import { GuidedCareTemplateActivitiesItemsForm } from "../../forms/TemplateActivitiesItems/GuidedCareTemplateActivitiesItemsForm";
import { useTemplateBreadcrumbGetQuery } from "@/pages/Templates/gql";

export const GuidedCareTemplateActivitiesItemsManageContainer = () => {
  const { templateId } = useParams<IGuidedCareTemplateActivitiesItemsManageContainerParams>();

  const { data } = useTemplateBreadcrumbGetQuery({
    variables: {
      id: templateId!,
    },
    skip: !templateId,
  });

  useSetGuidedCareTemplatesBreadcrumbs("ACTIVITIES_ITEM_LIST", data?.healthProgramTemplate?.name!);

  return <GuidedCareTemplateActivitiesItemsForm templateId={templateId!} />;
};
