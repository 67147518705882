/* eslint-disable max-lines */
import {
  FormActions,
  FormAutocomplete,
  FormCard,
  FormChipsInput,
  FormNumberField,
  FormSwitch,
  FormTextField,
  Grid,
  mapStringsToAutocompleteOptions,
  PageWrapper,
} from "@toolkit/ui";
import { FormProvider } from "react-hook-form";
import { useCustomForm } from "@toolkit/core";
import {
  healthParameterUpsertFormDefaultValues,
  healthParameterUpsertFormSchema,
  IHealthParameterUpsertFormValues,
} from "./HealthParameterUpsertFormSchema";
import { FC, useEffect } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useNavigate } from "react-router-dom";
import { CodeSystemCode, FieldStandard, HealthParameter, TemplateFieldType } from "@health/queries/types";
import { HealthParameterCategoriesAutocomplete, HealthParameterUnitsAutocomplete, SystemCodeAutocomplete } from "@health/autocompletes";
import { chartTypeOptions, fieldStandardOptions, templateFieldTypeOptions } from "@health/enum-options";
import { healthParametersPaths } from "../../constants";
import { convertHealthParameterToFormValues } from "../../others/utils";

export type IHealthParameterUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IHealthParameterUpsertFormValues;
  };
};

type HealthParameterUpsertFormProps = {
  healthParameter?: HealthParameter;
  submitButtonLabel: string;
  isLoading?: boolean;
  isButtonDisabled?: boolean;
  onChange: (event: IHealthParameterUpsertFormEvent) => void;
};

export const HealthParameterUpsertForm: FC<HealthParameterUpsertFormProps> = props => {
  const { healthParameter, submitButtonLabel, isLoading, isButtonDisabled, onChange } = props;

  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const form = useCustomForm<IHealthParameterUpsertFormValues>({
    defaultValues: healthParameterUpsertFormDefaultValues,
    schema: healthParameterUpsertFormSchema,
  });

  const { watch, setValues, handleSubmit } = form;

  const standard = watch("standard")?.value;
  const type = watch("type")?.value;
  const allowedValues = watch("allowedValues");

  const onSubmit = (values: IHealthParameterUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const onCancelClick = () => {
    navigate(healthParametersPaths.list.fullPath);
  };

  useEffect(() => {
    if (healthParameter) {
      const _healthParameter = convertHealthParameterToFormValues(healthParameter);
      setValues(_healthParameter);
    }
  }, [healthParameter, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={submitButtonLabel}
              onNavigation={onCancelClick}
              newButtonDisabled={isButtonDisabled}
            />
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormCard title={t("Health Parameter Information")} loading={isLoading} doYouHaveData>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormTextField name={"code"} label={t("Code")} disabled={!!healthParameter} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormTextField name={"display"} label={t("Display")} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormTextField name={"arabicDisplay"} label={t("Arabic Display")} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <HealthParameterCategoriesAutocomplete name={"category"} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <HealthParameterUnitsAutocomplete name={"unit"} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormAutocomplete name={"type"} label={t("Type")} disabled={!!healthParameter} options={templateFieldTypeOptions} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormAutocomplete name={"chartType"} label={t("Chart Type")} options={chartTypeOptions} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormAutocomplete name={"standard"} label={t("Coding Standard")} options={fieldStandardOptions} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <SystemCodeAutocomplete
                      name={"standardCode"}
                      label={t("Standard Code")}
                      disabled={!standard}
                      filter={{ codeSystemCode: standard === FieldStandard.Cpt ? CodeSystemCode.Cpt : CodeSystemCode.Loinc }}
                    />
                  </Grid>

                  {type === TemplateFieldType.String && (
                    <>
                      <Grid item xs={12} sm={8}>
                        <FormChipsInput disabled={!!healthParameter} name={"allowedValues"} placeholder={t("Allowed Values")} />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <FormAutocomplete
                          name={"defaultValue"}
                          label={t("Default Value")}
                          options={mapStringsToAutocompleteOptions(allowedValues)}
                        />
                      </Grid>
                    </>
                  )}

                  {type === TemplateFieldType.Number && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <FormNumberField name={"minNormalRangeValue"} label={t("Min Value")} isFloatAllowed />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormNumberField name={"maxNormalRangeValue"} label={t("Max Value")} isFloatAllowed />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} sm={6} md={12}>
                    <FormTextField name={"referenceRange"} multiline rows={5} label={t("Reference Range")} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={12}>
                    <FormSwitch name={"isActive"} label={t("Is Active")} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={12}>
                    <FormSwitch name={"isMandatory"} label={t("Is Mandatory")} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={12}>
                    <FormSwitch name={"isVisibleForPatient"} label={t("Is Visible For Patient")} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={12}>
                    <FormSwitch name={"allowManualEditForPatient"} label={t("Is manually entered by patient")} />
                  </Grid>
                </Grid>
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
