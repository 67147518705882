import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { guidedCareTemplatesPaths } from "pages/Templates/constants";

export const useSetGuidedCareTemplatesBreadcrumbs = (
  containerType: "LIST" | "CREATE" | "UPDATE" | "CLONE" | "ACTIVITIES_ITEM_LIST" | "ACTIVITY_ITEM_LIST",
  name?: string
) => {
  const { t } = useTranslation("gcadmin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Templates");

  const templateListBreadcrumb = useMemo(() => {
    return {
      name: t("Templates"),
      route: guidedCareTemplatesPaths.list.fullPath,
    };
  }, [t]);

  const setGuidedCareTemplateListContainerBreadcrumb = useCallback(() => {
    const guidedCareTemplateListContainerBreadcrumb = {
      title: breadcrumbTitle,
    };

    setBreadCrumb(guidedCareTemplateListContainerBreadcrumb);
  }, [breadcrumbTitle, setBreadCrumb]);

  const setGuidedCareTemplateCreateContainerBreadcrumb = useCallback(() => {
    const guidedCareTemplateCreateContainerBreadcrumb = {
      title: breadcrumbTitle,
      values: [templateListBreadcrumb, { name: t("New") }],
    };

    setBreadCrumb(guidedCareTemplateCreateContainerBreadcrumb);
  }, [breadcrumbTitle, setBreadCrumb, templateListBreadcrumb, t]);

  const setGuidedCareTemplateUpdateContainerBreadcrumb = useCallback(() => {
    if (name) {
      const guidedCareTemplateUpdateContainerBreadcrumb = {
        title: breadcrumbTitle,
        values: [templateListBreadcrumb, { name }],
      };

      setBreadCrumb(guidedCareTemplateUpdateContainerBreadcrumb);
    }
  }, [breadcrumbTitle, name, setBreadCrumb, templateListBreadcrumb]);

  const setGuidedCareTemplateCloneContainerBreadcrumb = useCallback(() => {
    if (name) {
      const guidedCareTemplateUpdateContainerBreadcrumb = {
        title: breadcrumbTitle,
        values: [templateListBreadcrumb, { name }, { name: t("Clone") }],
      };

      setBreadCrumb(guidedCareTemplateUpdateContainerBreadcrumb);
    }
  }, [breadcrumbTitle, name, setBreadCrumb, t, templateListBreadcrumb]);

  const setGuidedCareTemplateActivitiesItemListContainerBreadcrumb = useCallback(() => {
    if (name) {
      const guidedCareTemplateActivitiesItemListContainerBreadcrumb = {
        title: breadcrumbTitle,
        values: [templateListBreadcrumb, { name }, { name: t("Activities Items") }],
      };

      setBreadCrumb(guidedCareTemplateActivitiesItemListContainerBreadcrumb);
    }
  }, [name, breadcrumbTitle, templateListBreadcrumb, t, setBreadCrumb]);

  const setGuidedCareTemplateActivityItemListContainerBreadcrumb = useCallback(() => {
    if (name) {
      const guidedCareTemplateActivityItemsContainerBreadcrumb = {
        title: breadcrumbTitle,
        values: [{ name: t("Activities") }, { name }, { name: t("Activity Items") }],
      };

      setBreadCrumb(guidedCareTemplateActivityItemsContainerBreadcrumb);
    }
  }, [name, breadcrumbTitle, t, setBreadCrumb]);

  useEffect(() => {
    if (containerType === "LIST") {
      setGuidedCareTemplateListContainerBreadcrumb();
    } else if (containerType === "CREATE") {
      setGuidedCareTemplateCreateContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setGuidedCareTemplateUpdateContainerBreadcrumb();
    } else if (containerType === "CLONE") {
      setGuidedCareTemplateCloneContainerBreadcrumb();
    } else if (containerType === "ACTIVITIES_ITEM_LIST") {
      setGuidedCareTemplateActivitiesItemListContainerBreadcrumb();
    } else if (containerType === "ACTIVITY_ITEM_LIST") {
      setGuidedCareTemplateActivityItemListContainerBreadcrumb();
    }
  }, [
    containerType,
    setGuidedCareTemplateListContainerBreadcrumb,
    setGuidedCareTemplateCreateContainerBreadcrumb,
    setGuidedCareTemplateUpdateContainerBreadcrumb,
    setGuidedCareTemplateCloneContainerBreadcrumb,
    setGuidedCareTemplateActivitiesItemListContainerBreadcrumb,
    setGuidedCareTemplateActivityItemListContainerBreadcrumb,
  ]);
};
